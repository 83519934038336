import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip, Checkbox } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconChildSolid,
    IconBoosterSolid,
    IconMale,
    IconFemale,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';
import PassengerComments from '../../PassengerComments';

export default function PassengersTable(props) {
    const {
        passenger,
        togglePax,
        selectedPax,
        push,
        printView,
        comments,
        commentsLoading,
    } = props;
    const isChecked = selectedPax.indexOf(passenger.order_id) >= 0;
    const passengerTranslitName =
        passenger.name_transliterated !== '' &&
        passenger.name_transliterated !== passenger.name &&
        passenger.name_transliterated &&
        !printView;
    const passengerName = passengerTranslitName ? (
        <>
            <label>
                {passenger.name_transliterated}
                &nbsp;
                <Tooltip
                    id={passenger.name}
                    position="top"
                    content={passenger.name}
                    openOnHover
                    size="content-fit"
                >
                    <span className="reservation-passenger-item__name-wrapper">
                        <span className="reservation-passenger-item__name">
                            {`(${passenger.name})`}
                        </span>
                    </span>
                </Tooltip>
            </label>
        </>
    ) : (
        passenger.name
    );
    const genderIcons = {
        male: IconMale,
        female: IconFemale,
    };
    const genderIcon = genderIcons[passenger.gender] ? (
        <Icon InlineIcon={genderIcons[passenger.gender]} />
    ) : null;
    const paxItemCssClass = classNames('reservation-passenger-item', {
        'reservation-passenger-item--push': push,
    });
    const translate = useContext(TranslateContext);
    return (
        <div className={paxItemCssClass} data-dd-privacy="mask">
            {push ? (
                <label className="reservation-passenger-item__checkbox">
                    {passengerName}
                </label>
            ) : (
                <Checkbox
                    label={passengerName}
                    aria-label={translate(
                        'reservations.table.select_passenger_control'
                    )}
                    id={`${passenger.name}-${passenger.ticket_id}`}
                    value={String(isChecked)}
                    onChange={togglePax(passenger.order_id)}
                    checked={isChecked}
                    extraClasses="reservation-passenger-item__checkbox"
                    small
                />
            )}
            {passenger.is_unattended_child && (
                <Tooltip
                    size="content-fit"
                    openOnHover
                    id={`${passenger.name}-${passenger.ticket_id}-tooltip-um`}
                    content="UM"
                >
                    <Icon
                        InlineIcon={IconChildSolid}
                        style={{ fill: '#ffad00' }}
                    />
                </Tooltip>
            )}
            {passenger.child_on_lap && <Icon InlineIcon={IconBoosterSolid} />}
            {!passenger.is_adult && !passenger.is_unattended_child ? (
                <Tooltip
                    size="content-fit"
                    openOnHover
                    id={`${passenger.name}-${passenger.ticket_id}-tooltip-children`}
                    content="Child"
                >
                    <Icon InlineIcon={IconChildSolid} />
                </Tooltip>
            ) : null}
            &nbsp;
            {genderIcon}
            &nbsp;
            {!printView && (
                <PassengerComments
                    passenger={passenger}
                    initComments={comments}
                    initLoading={commentsLoading}
                />
            )}
        </div>
    );
}

PassengersTable.propTypes = {
    passenger: PropTypes.object.isRequired,
    togglePax: PropTypes.func.isRequired,
    selectedPax: PropTypes.array.isRequired,
    push: PropTypes.bool.isRequired,
    printView: PropTypes.bool,
};
PassengersTable.defaultProps = { printView: false };
