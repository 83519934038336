import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconTrainWagon,
    IconSeat,
    IconBedSeat,
    IconStar,
} from '@flixbus/honeycomb-icons-react';
import { SORT_BY_SCOPE } from './SeatAssignmentDialog';

const SPECIAL_CATS = [
    'panorama_seat',
    'premium_seat',
    'semi_bed_seat',
    'bed_seat',
    'free_bed_seat',
];

const ICONS_MAP = {
    panorama_seat: IconStar,
    premium_seat: IconStar,
    semi_bed_seat: IconBedSeat,
    bed_seat: IconBedSeat,
    free_bed_seat: IconBedSeat,
    table_seat: IconSeat,
    free_seat: IconSeat,
    single_seat: IconSeat,
    front_seat: IconSeat,
    train_panorama_seat: IconSeat,
    train_premium_seat: IconSeat,
    train_comfort_seat: IconSeat,
    train_table_seat: IconSeat,
};

export default function SeatAssignmentDropdown(props) {
    const {
        options,
        onChange,
        pax,
        placeholder,
        coachesMap,
        selected,
        disabled,
    } = props;
    const [active, setActive] = useState(false);
    const [recommended, setRecommended] = useState([]);
    const [other, setOther] = useState([]);

    const isCoach = coachesMap && Object.keys(coachesMap).length > 0;

    const matchGender = (pax, seat) => {
        return pax.gender
            ? seat.gender.some(
                  (item) => item.toLowerCase() === pax?.gender?.toLowerCase()
              )
            : true;
    };

    const clickOutside = useCallback(
        (e) => {
            if (active === true) {
                setActive(false);
            }
        },
        [active, setActive]
    );

    const ListItem = (item, pax, idx) => {
        const itemLabel = coachesMap ? `${coachesMap[item]}` : item.label;

        return (
            <li
                className="seats-dropdown__list-item"
                key={pax.id + '_' + item.id + '_' + idx}
                onClick={() => {
                    if (!item.disabled) {
                        setActive(false);
                        onChange(item);
                    }
                }}
                disabled={!matchGender(pax, item) || item.disabled}
            >
                {coachesMap ? (
                    <>
                        <Icon InlineIcon={IconTrainWagon} /> {itemLabel}
                    </>
                ) : (
                    <>
                        <Icon InlineIcon={ICONS_MAP[item.category]} />{' '}
                        {itemLabel}{' '}
                        {SPECIAL_CATS.includes(item.category)
                            ? `(${item.category.replace('_', '-')})`
                            : null}
                    </>
                )}
            </li>
        );
    };

    // hide panel with outside clicks
    useEffect(() => {
        function clickToClose(e) {
            const className = e.target && e.target.className;
            /**
             * @todo come up with another solutions to detect element,
             * more specific. Issues with SVGElement.
             */
            if (
                typeof className === 'string' &&
                (className.includes('hcr-popup') ||
                    className.includes('seat-assignment-dialog'))
            ) {
                clickOutside();
            }
        }
        if (active === true) {
            document.addEventListener('click', clickToClose, false);
        }
        return () => {
            document.removeEventListener('click', clickToClose, false);
        };
    }, [active, clickOutside]);

    // Split option between recommended and others
    useEffect(() => {
        if (!isCoach) {
            const rec = [...options].splice(0, SORT_BY_SCOPE);
            const other = [...options].splice(SORT_BY_SCOPE, options.length);

            setRecommended(rec);
            setOther(other);
        }
    }, [options, coachesMap, isCoach]);

    return (
        <div className="seats-dropdown">
            {active && (
                <ul className="seats-dropdown__list">
                    {isCoach ? (
                        options.map((seat, idx) => ListItem(seat, pax, idx))
                    ) : (
                        <>
                            {recommended.length > 0 ? (
                                <>
                                    <li className="seats-dropdown__list-item--heading">
                                        Recommended seats:
                                    </li>
                                    {recommended.map((seat, idx) =>
                                        ListItem(seat, pax, idx)
                                    )}
                                </>
                            ) : null}
                            {other.length > 0 ? (
                                <>
                                    <li className="seats-dropdown__list-item--sep"></li>
                                    <li className="seats-dropdown__list-item--heading">
                                        Other seats:
                                    </li>
                                    {other.map((seat, idx) =>
                                        ListItem(seat, pax, idx)
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </ul>
            )}
            <Button
                onClick={() => setActive(true)}
                extraClasses={
                    !coachesMap
                        ? 'seats-dropdown__button'
                        : 'seats-dropdown__button seats-dropdown__button--coach'
                }
                disabled={disabled}
            >
                {selected ? (
                    <span>
                        <Icon
                            InlineIcon={
                                isCoach
                                    ? IconTrainWagon
                                    : ICONS_MAP[selected.category]
                            }
                        />
                        {isCoach ? coachesMap[selected] : selected.label}
                    </span>
                ) : (
                    placeholder
                )}
            </Button>
        </div>
    );
}

SeatAssignmentDropdown.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    pax: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    coachesMap: PropTypes.object,
    isCoach: PropTypes.bool,
    disabled: PropTypes.bool,
};
