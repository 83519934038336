import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
    Heading,
    FormRow,
    Button,
    Popup,
    PopupSection,
    Tag,
    Box,
    Infobox,
    ListWrapper,
    ListWrapperItem,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconLabel,
    IconCircleSolid,
    IconClose,
} from '@flixbus/honeycomb-icons-react';
import apiTagsBank from '../../../api/TagsBankApi';
import { useTags, useApi } from '../hooks';
import { TranslateContext } from '../../System/Translations';
import { ListPicker } from '../FormElements/';

export default function TagsForm(props) {
    const { active, rides, onClose } = props;
    const [addResponse, addTags, addReset] = useApi(
        'post',
        'api/ride/tags/add',
        apiTagsBank
    );
    const [removeResponse, removeTags, removeRest] = useApi(
        'post',
        'api/ride/tags/remove',
        apiTagsBank
    );
    const [tags, request] = useTags(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const translate = useContext(TranslateContext);

    const title = translate('bulk-tags.popup.title');
    const labelId = title.toLowerCase().replace(' ', '-');

    useEffect(() => {
        request();
    }, [request]);

    const getFailedMessage = (data = []) => {
        const { failed } = data !== null ? data : { failed: [] };
        if (failed && failed.length > 0) {
            const failedRidesList = new Map(
                failed.map((item) => [item.rideId, item])
            ).values();

            return (
                <ListWrapper small appearance="linked">
                    <ListWrapperItem key="failed-rides-title">
                        Rides are failed:
                    </ListWrapperItem>
                    {[...failedRidesList].map((r) => (
                        <ListWrapperItem
                            key={`failed-rides-${r.rideId}`}
                            RouterLink={RouterLink}
                            to={`ride-view/${r.rideId}`}
                            target="_blank"
                        >
                            {r.rideId}
                        </ListWrapperItem>
                    ))}
                </ListWrapper>
            );
        } else {
            return null;
        }
    };

    const getErrorMessage = useCallback(() => {
        const error = addResponse.error || removeResponse.error;
        return error ? error.toString() : false;
    }, [addResponse.error, removeResponse.error]);

    const options =
        tags !== null
            ? tags.map((tag) => {
                  return {
                      value: tag.id,
                      title: tag.name,
                      name: tag.name,
                      color: tag.color,
                  };
              })
            : [];

    const selectTag = useCallback(
        (id) => {
            const tagsSet = new Set(selectedTags);
            tagsSet.add(id);
            setSelectedTags(Array.from(tagsSet));
        },
        [selectedTags, setSelectedTags]
    );
    const unSelectTag = useCallback(
        (id) => () => {
            setSelectedTags(selectedTags.filter((t) => t !== id));
        },
        [selectedTags, setSelectedTags]
    );

    const sendAddTagsRequest = useCallback(() => {
        addReset();
        removeRest();

        const request = rides.map((ride) => {
            return {
                rideId: ride,
                tags: selectedTags,
            };
        });

        addTags(request);
    }, [rides, selectedTags, addReset, removeRest, addTags]);

    const sendRemoveTagsRequest = useCallback(() => {
        addReset();
        removeRest();

        const request = rides.map((ride) => {
            return {
                rideId: ride,
                tags: selectedTags,
            };
        });

        removeTags(request);
    }, [rides, selectedTags, addReset, removeRest, removeTags]);

    return (
        <Popup
            aria-labelledby={labelId}
            active={active}
            onOverlayClick={onClose}
        >
            <PopupSection type="icon" extraClasses="my-class">
                <Icon InlineIcon={IconLabel} size={12} title={title} />
            </PopupSection>
            <PopupSection type="title">
                <Heading id={labelId} size={2}>
                    {title}
                </Heading>
            </PopupSection>
            <PopupSection type="content">
                <FormRow>
                    <ListPicker
                        id="tags-bulk-picker"
                        aria-label="tags-autocomplete"
                        options={options}
                        InlineIcon={IconLabel}
                        placeholder={translate(
                            'bulk-tags.popup.autocomplete-placeholder'
                        )}
                        onChange={(id) => {
                            selectTag(id);
                        }}
                        shouldResetValue={() => true}
                        renderOption={(tag) => (
                            <Tag
                                key={`${tag.name}-tags-bulk-picker`}
                                Elem="div"
                                small
                                outlined
                                style={{
                                    borderColor: tag.color,
                                }}
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={() => (
                                        <IconCircleSolid
                                            style={{ fill: tag.color }}
                                        />
                                    )}
                                />
                                {tag.name}
                            </Tag>
                        )}
                    />
                </FormRow>
                <Box>
                    {selectedTags.length === 0
                        ? translate('bulk-tags.popup.no-tags')
                        : null}
                    {tags
                        ?.filter((item) => selectedTags.includes(item.id))
                        .map((tag) => (
                            <Tag
                                key={`${tag.id}-tags-selected`}
                                Elem="button"
                                outlined
                                style={{
                                    borderColor: tag.color,
                                    marginRight: '5px',
                                    marginTop: '5px',
                                }}
                                onClick={unSelectTag(tag.id)}
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={() => (
                                        <IconCircleSolid
                                            style={{ fill: tag.color }}
                                        />
                                    )}
                                />
                                {tag.name}
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconClose}
                                />
                            </Tag>
                        ))}
                </Box>
            </PopupSection>
            <PopupSection type="content">
                {
                    // there is no response from the API even in success
                    // so the data is '', but we still need to show a success
                    addResponse.data !== null ||
                    removeResponse.data !== null ? (
                        getFailedMessage(
                            addResponse.data || removeResponse.data
                        ) ? (
                            <Infobox small appearance="warning">
                                {getFailedMessage(
                                    addResponse.data || removeResponse.data
                                )}
                            </Infobox>
                        ) : (
                            <Infobox small appearance="success">
                                {translate('bulk-tags.popup.success')}
                            </Infobox>
                        )
                    ) : null
                }

                {getErrorMessage() ? (
                    <Infobox small appearance="danger">
                        {getErrorMessage()}
                    </Infobox>
                ) : null}
            </PopupSection>
            <PopupSection type="actions">
                <Button
                    appearance="secondary"
                    onClick={sendAddTagsRequest}
                    loading={addResponse.pending}
                    disabled={selectedTags.length === 0}
                >
                    {translate('bulk-tags.popup.button.add')}
                </Button>
                <Button
                    id="accessible-popup-close"
                    appearance="primary"
                    onClick={() => {
                        sendRemoveTagsRequest();
                    }}
                    loading={removeResponse.pending}
                    disabled={selectedTags.length === 0}
                >
                    {translate('bulk-tags.popup.button.remove')}
                </Button>
                <Button
                    id="accessible-popup-close"
                    appearance="tertiary"
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    {translate('bulk-tags.popup.button.close')}
                </Button>
            </PopupSection>
        </Popup>
    );
}
TagsForm.propTypes = {
    rides: PropTypes.array,
    active: PropTypes.bool,
    onClose: PropTypes.func,
};
TagsForm.defaultProps = { rides: [], active: false, onClose: () => {} };
