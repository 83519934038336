import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';
import useGetCommentHistory from '../../CommentsHooks/useGetCommentHistory';
import { Table, Skeleton } from '@flixbus/honeycomb-react';
import { formatDate } from '../../../util/date';

export default function History(props) {
    const { notify, commentId, format } = props;
    const { history, error, loading, getCommentHistory } =
        useGetCommentHistory(commentId);
    const translate = useContext(TranslateContext);

    const showLoading = () => {
        if (loading && history.length === 0) {
            return (
                <>
                    <Skeleton Elem="div" width="lg" height="sm" />
                    <Skeleton Elem="div" width="lg" height="sm" />
                    <Skeleton Elem="div" width="lg" height="sm" />
                </>
            );
        }
        return null;
    };

    const showNoComments = () => {
        if (loading === false && history.length === 0) {
            return translate('with-comments-api.no-comments');
        }
        return null;
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    const rows = history.map(({ updatedAt, updatedBy, content, author }) => {
        return [updatedBy || author, content, [formatDate(updatedAt, format)]];
    });

    useEffect(() => {
        if (commentId) {
            getCommentHistory();
        }
    }, [commentId, getCommentHistory]);

    return (
        <>
            {showLoading()}
            {showNoComments()}

            {history.length > 0 ? (
                <Table
                    extraClasses="single-comment__history"
                    rows={rows}
                ></Table>
            ) : null}
        </>
    );
}

History.propTypes = {
    notify: PropTypes.func.isRequired,
    commentId: PropTypes.number.isRequired,
    format: PropTypes.string,
};
