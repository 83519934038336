import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { NotifyContext } from '../../NotificationSystem/context';

const FIELDS_MAP = [
    'Ride ID',
    'Order ID',
    'Passenger Name',
    'Email',
    'Phone number',
    'Status',
    'Identification type',
    'Identification number',
    'Departure',
    'Arrival',
    'From',
    'To',
    'Seat Number',
    'Deck/Coach',
    'Type',
    'Language',
];

function parseTimeline(timeline) {
    if (!Array.isArray(timeline)) {
        return {};
    }
    return timeline.reduce((acc, entry) => {
        const result = { ...acc };
        const { entry_type, uuid, arrival, departure } = entry;
        if (entry_type === 'stop') {
            result[uuid] = {
                arrival,
                departure,
            };
        }
        return result;
    }, {});
}

async function getSaveHandler(uuid) {
    return await window.showSaveFilePicker({
        suggestedName: `pax-${uuid}`,
        types: [
            {
                description: 'CSV file',
                accept: { 'text/plain': ['.csv'] },
            },
        ],
    });
}

async function write(handler, content) {
    const writable = await handler.createWritable();
    await writable.write(content);
    await writable.close();
}

const TIMEOUT = 8000;
const errorMsg =
    'Your browser does not support this feature try to update it, or use Chrome or Edge';

export default function useCSV(orders, rideUuid, timeline) {
    const stopsMap = useMemo(() => parseTimeline(timeline), [timeline]);
    const notify = useContext(NotifyContext);
    const [isLoading, setIsLoading] = useState(false);
    const generate = useCallback(async () => {
        let output = orders.reduce(
            (acc, order) => {
                const fromName = order.route.from_name.split(',').join(' -');
                const toName = order.route.to_name.split(',').join(' -');
                let result = [...acc];

                return result.concat(
                    order.passengers.map((pax) => [
                        rideUuid,
                        order.id,
                        pax.name,
                        pax.email,
                        pax.phone_number,
                        pax.checkin_status,
                        pax.identification_type || '-',
                        pax.identification_number || '-',
                        stopsMap[order.route.from_uuid].departure,
                        stopsMap[order.route.to_uuid].arrival,
                        `${order.route.from_code}-${fromName}`,
                        `${order.route.to_code}-${toName}`,
                        pax.seat_type.seat_label,
                        pax.seat_type.deck,
                        pax.is_adult
                            ? 'adult'
                            : pax.is_unattended_child
                            ? 'UM'
                            : 'child',
                        order.language,
                    ])
                );
            },
            [FIELDS_MAP]
        );
        setIsLoading(true);
        getSaveHandler(rideUuid)
            .then((h) => {
                write(h, output.join('\n'))
                    .then(() => {
                        notify({
                            type: 'success',
                            message: `Success export to the ${h.name}`,
                        });
                        setIsLoading(false);
                    })
                    .catch(() => {
                        notify({
                            type: 'danger',
                            message: 'Export to CSV file failed',
                        });
                        setIsLoading(false);
                    });
            })
            .catch((e) => {
                if (e.toString().indexOf('AbortError') !== -1) {
                    notify({
                        type: 'warning',
                        message: 'Export to CSV file was aborted',
                    });
                } else {
                    notify({
                        type: 'danger',
                        message: errorMsg,
                    });
                }
                setIsLoading(false);
            });
    }, [orders, rideUuid, notify, stopsMap]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isLoading) {
                notify({
                    type: 'danger',
                    message: errorMsg,
                });
                setIsLoading(false);
            }
        }, TIMEOUT);

        return () => clearTimeout(timeout);
    }, [isLoading, notify]);

    return [isLoading, generate];
}
