import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Textarea, Button, FormRow } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import useEditComment from '../../CommentsHooks/useEditComment';
import { NotifyContext } from '../../NotificationSystem/context';

function normalizeContent(content) {
    return content.replace(/[\r\n\s\t]/gm, '');
}

export default function EditComment({
    commentId,
    content: prevContent,
    onCommentAdd = () => {},
    onEditModeExit,
}) {
    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);
    const [content, setContent] = useState(prevContent);

    const { comment, error, saving, editComment } = useEditComment(commentId);

    useEffect(() => {
        if (
            comment &&
            normalizeContent(comment.content) === normalizeContent(content) &&
            error === null
        ) {
            setContent('');
            onCommentAdd(comment);
        }
    }, [comment, error, content, onCommentAdd]);

    const handleContent = (e) => {
        const { value } = e.target;
        setContent(value);
    };

    const save = () => {
        editComment(content);
    };

    const isValid = () => {
        return content.trim().length > 0;
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    useEffect(() => {
        const editArea = document.getElementById(`update-comment-${commentId}`);
        if (editArea) {
            const end = editArea.value.length;
            editArea.setSelectionRange(end, end); // put cursor at the end of the string value
            editArea.focus();
        }
    }, [commentId]);

    return (
        <div className="comment-content">
            <div className="comment-content__form">
                <FormRow extraClasses="comment-content__control">
                    <Textarea
                        id={`update-comment-${commentId}`}
                        value={content}
                        onChange={(e) => handleContent(e)}
                        label={' '}
                        disabled={saving}
                        extraClasses="comment-content__textarea focused"
                    />
                </FormRow>
                <div className="comment-content__submit-line comment-content__submit-line--edit">
                    <Button
                        appearance="tertiary"
                        onClick={onEditModeExit}
                        disabled={saving}
                        loading={saving}
                    >
                        {translate('passengers.comments_form.button_cancel')}
                    </Button>
                    <Button
                        appearance="secondary"
                        onClick={save}
                        disabled={!isValid() || saving}
                        loading={saving}
                    >
                        {translate('add-comment.save')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

EditComment.propTypes = {
    commentId: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    onCommentAdd: PropTypes.func.isRequired,
    onEditModeExit: PropTypes.func.isRequired,
};
