import React, { useContext, useEffect, useState } from 'react';
import {
    Heading,
    Textarea,
    Button,
    FormRow,
    List,
} from '@flixbus/honeycomb-react';
import { Icon, IconDocument } from '@flixbus/honeycomb-icons-react';
import AttachFile from './AttachFile';
import { TranslateContext } from '../../System/Translations';
import useSaveComments from '../../CommentsHooks/useSaveComments';
import { NotifyContext } from '../../NotificationSystem/context';

function normalizeContent(content) {
    return content.replace(/[\r\n\s\t]/gm, '');
}

export default function AddComment({ rideUuid, onCommentAdd = () => {} }) {
    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);
    const [content, setContent] = useState('');
    const [attachments, setAttachments] = useState([]);

    const { comment, error, saving, saveComment } = useSaveComments(
        rideUuid,
        'ride'
    );

    useEffect(() => {
        if (
            comment &&
            normalizeContent(comment.content) === normalizeContent(content) &&
            error === null
        ) {
            setContent('');
            setAttachments([]);
            onCommentAdd(comment);
        }
    }, [comment, error, content, onCommentAdd]);

    const handleContent = (e) => {
        const { value } = e.target;
        setContent(value);
    };

    const handleAttachments = (attach) => {
        const newAttachments = attachments.concat(attach);
        setAttachments(newAttachments);
    };

    const save = () => {
        saveComment(content, attachments);
    };

    const isValid = () => {
        return content.trim().length > 0;
    };

    const onEditAreaFocus = () => {
        const editArea = document.getElementById('ride-comments');
        if (!editArea.classList.contains('focused')) {
            editArea.classList.add('focused');
        }
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    return (
        <div className="comment-content">
            <Heading size={2}>{translate('comments.ride-comments')}</Heading>

            <div className="comment-content__form">
                <FormRow extraClasses="comment-content__control">
                    <Textarea
                        id="ride-comments"
                        value={content}
                        onChange={(e) => handleContent(e)}
                        onFocus={onEditAreaFocus}
                        label={translate('comments.enter-comment')}
                        disabled={saving}
                        placeholder={translate('comments.placeholder')}
                        extraClasses="comment-content__textarea"
                    />
                </FormRow>
                {attachments.length > 0 && (
                    <List extraClasses="comment-content__attached-files">
                        {attachments.map((f) => (
                            <li key={f.name}>
                                <Icon InlineIcon={IconDocument} /> {f.name} -{' '}
                                {f.size} bytes
                            </li>
                        ))}
                    </List>
                )}
                <div className="comment-content__submit-line">
                    <AttachFile onDrop={handleAttachments} />
                    <Button
                        appearance="secondary"
                        onClick={save}
                        disabled={!isValid() || saving}
                        loading={saving}
                    >
                        {translate('add-comment.save')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
