import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupSection, Button } from '@flixbus/honeycomb-react';
import { Icon, IconDocument } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import { IFrame } from '../IFrame';
import './style.scss';

const PAX_LIST_NEBULA_HOST =
    'https://paxlist-master-finance-offlinesales-public.ew1p1.k8s.flix.tech';

export default function PaxListPdfDialogWithIframe(props) {
    const { rideUuid } = props;
    const [isOpen, setOpen] = useState(false);
    const t = useContext(TranslateContext);

    // close popup and stop timer for pending request
    function onClose() {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <span
                className="timeline-toolbar-icon-button"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Icon
                    InlineIcon={IconDocument}
                    title={t('pax-list-pdf.button.open-dialog')}
                />
                &nbsp;
                {t('pax-list-pdf.button.open-dialog')}
            </span>
            <Popup active={isOpen}>
                <PopupSection type="title">
                    {t('pax-list-pdf.popup-title')}
                </PopupSection>
                <PopupSection type="content">
                    {isOpen && (
                        <IFrame
                            className="rideview-paxlist-iframe"
                            src={`${PAX_LIST_NEBULA_HOST}/${rideUuid}?lang=${t.language}`}
                            title={t('reservations.table.pax-list-pdf')}
                        />
                    )}
                </PopupSection>
                <PopupSection type="actions">
                    <Button appearance="secondary" onClick={onClose}>
                        {t('pax-list-pdf.button.close-dialog')}
                    </Button>
                </PopupSection>
            </Popup>
        </React.Fragment>
    );
}

PaxListPdfDialogWithIframe.propTypes = {
    rideUuid: PropTypes.string.isRequired,
};
