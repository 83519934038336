import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { getUser } from '../../auth';

import useSticky from './hooks/useSticky';

import RideView from '../RideView';
import IndexPage from '../IndexPage';
import RideSearch from '../RideSearch';
import RideCapacity from '../RideCapacity';
import AppHeader from '../AppHeader';
import StickyWrapper from './StickyWrapper';
import ActionLogger from '../ActionLogger';

import './App.scss';

export default function App(props) {
    const { onWhoIsOnPageChanged } = props;
    const { isSticky, element } = useSticky();

    // init Formbricks with user data
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const user = getUser();

            if (!window.formbricksInitialized && window.formbricks) {
                window.formbricks.init({
                    environmentId: 'cm1g8oe5h15d4rnlpryj46xla',
                    apiHost: 'https://surveys.cro.flix.tech',
                    userId: user.userName,
                });
                window.formbricksInitialized = true;
            }
        }
    }, []);

    return (
        <Router>
            <React.Fragment>
                <AppHeader sticky={isSticky} />

                <StickyWrapper element={element}>
                    <div
                        className={
                            isSticky
                                ? 'ride-view__site-content sticky-header'
                                : 'ride-view__site-content'
                        }
                    >
                        <Route exact path="/" component={IndexPage} />
                        <Route path="/action-logger" component={ActionLogger} />
                        <Route
                            path="/ride-view/:id"
                            render={(p) =>
                                p.match.isExact ? (
                                    <Redirect to={`${p.match.url}/timeline`} />
                                ) : (
                                    <RideView
                                        {...p}
                                        onWhoIsOnPageChanged={
                                            onWhoIsOnPageChanged
                                        }
                                    />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/ride-view/:id/timeline-old"
                            render={(p) => {
                                return (
                                    <Redirect
                                        to={`/ride-view/${p.match.params.id}/timeline`}
                                    />
                                );
                            }}
                        />
                        <Route exact path="/search" component={RideSearch} />
                        <Route
                            exact
                            path="/search/:version"
                            component={RideSearch}
                        />
                        <Route
                            exact
                            path="/capacity/:ids"
                            component={RideCapacity}
                        />
                    </div>
                </StickyWrapper>
            </React.Fragment>
        </Router>
    );
}

App.propTypes = {
    whoIsOnPage: PropTypes.array.isRequired,
    onWhoIsOnPageChanged: PropTypes.func.isRequired,
    translate: PropTypes.func,
};

App.defaultProps = {
    translate: (key) => key,
};
