import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconBoosterSolid } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import { SeatsAndCoaches, SeatsList } from './SeatsAndCoaches';

const SeatAssignment = ({
    passengers,
    availableCoaches,
    availableSeats,
    coachesMap,
    onSelect,
    seatSelection,
}) => {
    const t = useContext(TranslateContext);

    return passengers.map((pax, idx) => {
        const selectedSeat = Object.keys(seatSelection).find(
            (seat) => seatSelection[seat].ticketId === pax.ticket_id
        );
        return (
            <div key={pax.id + idx}>
                {idx === 0 ? (
                    <div className="seat-assignment-dialog__seats-row">
                        <div className="seat-assignment-dialog__seats-label"></div>
                        <div className="seat-assignment-dialog__seats-list">
                            {pax.seat_type.deck ? (
                                <>
                                    <div className="seat-assignment-dialog__seats-select">
                                        {t('reservations.table.coach')}
                                    </div>
                                    <div className="seat-assignment-dialog__seats-select">
                                        {t('reservations.table.seats')}
                                    </div>
                                </>
                            ) : (
                                <div className="seat-assignment-dialog__seats-select">
                                    {t('reservations.table.seats')}
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
                <div className="seat-assignment-dialog__seats-row" key={pax.id}>
                    <div className="seat-assignment-dialog__seats-label">
                        <span>
                            {pax.child_on_lap && (
                                <Icon
                                    extraClasses="child-icon"
                                    InlineIcon={IconBoosterSolid}
                                />
                            )}
                            <span>{pax.name}</span>
                        </span>
                        {selectedSeat ? (
                            <span>
                                {pax.seat_type.deck
                                    ? `${
                                          coachesMap[
                                              seatSelection[selectedSeat]
                                                  .position.deck
                                          ]
                                      } | `
                                    : null}
                                {seatSelection[selectedSeat].label}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="seat-assignment-dialog__seats-list">
                        {pax.seat_type.deck ? (
                            <SeatsAndCoaches
                                coaches={availableCoaches}
                                coachesMap={coachesMap}
                                pax={pax}
                                onSelect={onSelect}
                                disabled={pax.child_on_lap}
                            />
                        ) : (
                            <SeatsList
                                seats={availableSeats}
                                pax={pax}
                                onSelect={onSelect}
                                disabled={pax.child_on_lap}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    });
};

SeatsAndCoaches.propTypes = {
    passengers: PropTypes.array.isRequired,
    availableCoaches: PropTypes.array.isRequired,
    availableSeats: PropTypes.array.isRequired,
    coachesMap: PropTypes.object.isRequired,
};
SeatsAndCoaches.defaultProps = {
    passengers: [],
    availableCoaches: [],
    availableSeats: [],
    coachesMap: {},
};

export default SeatAssignment;
